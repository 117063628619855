import { useNavigate } from "react-router-dom";
import "./wrapBulletPoint.scss";

function WrapBulletPoint({ copy, indent, imgSoure }) {

  const navigate = useNavigate();

  const goToWrapPage = () => {
    navigate("/our-wrap");
  };

  return (
    <div className="wrapBulletPoint" onClick={goToWrapPage}>
      <img src={imgSoure} className="wrapBulletPoint__img" />
      <p className="wrapBulletPoint__copy">
        <span className="wrapBulletPoint__indent"> {indent}</span>
        {copy}
      </p>
    </div>
  );
}

export default WrapBulletPoint;
