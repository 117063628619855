import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./Hero.scss";

import image1 from "../../assets/pictures/ssstills/IMG_0887-min.JPG";
import image2 from "../../assets/pictures/ssstills/IMG_1027-min.JPG";
import image3 from "../../assets/pictures/ssstills/IMG_0981-min.JPG";
import image4 from "../../assets/pictures/ssstills/IMG_1010-min.JPG";
import image5 from "../../assets/pictures/ssstills/IMG_0915-min.JPG";
import image6 from "../../assets/pictures/ssstills/IMG_0933-min.JPG";
import image7 from "../../assets/pictures/ssstills/IMG_0872-min.JPG";

function Hero() {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const heroElement = document.querySelector(".hero__imgBox");
    const heroImages = [image1, image2, image3, image4, image5, image6, image7];

    heroImages.forEach((imgSrc) => {
      const img = new Image();
      img.src = imgSrc;
    });

    function changeHeroBg() {
      heroElement.style.backgroundImage = `url(${heroImages[index]})`;
      setIndex((prevIndex) => (prevIndex + 1) % heroImages.length);
    }

    const intervalId = setInterval(changeHeroBg, 3000);

    return () => clearInterval(intervalId);
  }, [index]);

  return (
    <div className="hero__imgBox">
      <div className="hero__content">
        <p className="hero__logline">
          We are Smart Stretch<span className="hero__logline-dot">.</span>
        </p>

        <p className="hero__desc">
          Your direct source for industrial & commercial stretch wrap products
        </p>

        <div className="hero__btns">
          <Link to={"/our-wrap"} class="btn">
            Learn more
          </Link>

          <Link to={"/contact"} class="btn">
            Contact
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Hero;
