import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { useLocation } from "react-router-dom";

import "./Header.scss";

import Tel from "../Tel/Tel";

function Header() {
  const location = useLocation();

  const logoScroll = () => {
    if (location.pathname === "/") {
      scroll.scrollToTop({ duration: 750 });
    } else {
      window.scrollTo(0, 0);
    }
  };

  return (
    <header className="header">
      <ScrollLink to="/" onClick={logoScroll}>
        <Link to={"/"}>
          <div className="header__logo-box">
            <p className="header__logo">SS</p>
            <h1 className="header__name">SMART STRETCH</h1>
          </div>
        </Link>
      </ScrollLink>

      <ul className="header__nav">
        <li className="header__phone">
          <Tel />
        </li>
        <div className="testtt">
          <ScrollLink to="/" onClick={logoScroll}>
            <NavLink
              to={"/"}
              className={
                location.pathname === "/"
                  ? "header__link active"
                  : "header__link"
              }
            >
              Home
            </NavLink>
          </ScrollLink>
          <NavLink to={"/our-wrap"} activeClassName="active">
            <li className="header__link">Our wrap</li>
          </NavLink>
          <NavLink to={"/testimonials"} activeClassName="active">
            <li className="header__link">Testimonials</li>
          </NavLink>
          <NavLink to={"/contact"} activeClassName="active">
            <li className="header__link">Contact</li>
          </NavLink>
        </div>
      </ul>
    </header>
  );
}

export default Header;
