import React, { useState } from "react";
import { useForm, ValidationError } from "@formspree/react";

import "./contactForm.scss";

import emailIcon from "../../assets/icons/email2.png";

import MailTo from "../MailTo/MailTo";
import Tel from "../Tel/Tel";

function ContactForm() {
  const [state, handleSubmit] = useForm("myyrwoqa");
  if (state.succeeded) {
    return (
      <>
        <div className="contact__success">
          <div className="contact__successCopy">
            <p>Thanks for reaching out</p>
            <p>We will get back to you within the next 12 hours</p>
          </div>
          <img src={emailIcon} className="contact__successImg" />
        </div>
      </>
    );
  }

  return (
    <>
      <p className="contact__intro">
        For product enquires and assesments please contact us at
        <span className="contact__introEmail"><MailTo/></span>
        or <br />
        <span className="contact__introEmail"><Tel/></span>
      </p>

      <p className="contact__header">Contact Us</p>

      <form className="contactForm" onSubmit={handleSubmit}>
        <div className="contactForm__fname">
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            className="contactForm__fnameInput"
            placeholder="e.g Kofi"
          ></input>
        </div>

        <div className="contactForm__lname">
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            className="contactForm__lnameInput"
            placeholder="e.g Charles"
          ></input>
        </div>

        <div className="contactForm__phone">
          <label htmlFor="phone">Phone</label>
          <input
            type="text"
            id="phone"
            name="phone"
            className="contactForm__phoneInput"
            placeholder="123 - 456 - 7890"
          ></input>
        </div>

        <div className="contactForm__email">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            className="contactForm__emailInput"
            placeholder="example@mail.com"
          ></input>
          <ValidationError
            prefix="=Email"
            field="email"
            errors={state.errors}
          />
        </div>

        <div className="contactForm__message">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            placeholder="Type your message here"
            className="contactForm__messageInput"
          ></textarea>
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
        </div>

        <button
          type="submit"
          className="contactForm__submitBtn"
          disabled={state.submitting}
        >
          SUBMIT
        </button>
      </form>
    </>
  );
}

export default ContactForm;
