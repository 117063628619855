import "./testimonials.scss";

import Testimonial from "../../components/Quotes/Testimonial";

function Testimonials() {
  return (
    <>
      <h1 className="testimonialsPage__header">Testimonials</h1>;
      <div className="testimonialsPage__container">
        <Testimonial
          person={"Mohammed, Custom packaging company"}
          quote={
            "Our heartfelt gratitude to the dedicated team at Smart Stretch for delivering exceptional customer service. Your commitment has solidified Smart Stretch as our preferred vendor, and we cherish the enduring, long-term relationship we've cultivated."
          }
          hideMore={true}
        />

        <Testimonial
          person={"Prince, Equipment manufacturer"}
          quote={
            "We take great satisfaction in our flourishing business relationship with Smart Stretch, particularly with our dedicated Sales Representative. Their commitment to understanding our business and shipping supply needs has been unparalleled. Smart Stretch consistently delivers superior service and top-quality products at competitive prices. The problem-solving approach and profound industry knowledge have provided the precise solutions we required."
          }
          hideMore={true}
        />
        <Testimonial
          person={"Francis, Warehousing firm"}
          quote={
            "Smart Stretch has proven to be the cornerstone of our operational success. With an unwavering commitment to excellence, their team ensures our supply needs are not just met but exceeded. It's more than a business relationship; it's a partnership built on trust, expertise, and unparalleled service."
          }
          hideMore={true}
        />
        <Testimonial
          person={"Ernest, Logistic centre"}
          quote={
            "Our collaboration with Smart Stretch has elevated our standards for warehouse solutions. The exceptional service, combined with the industry acumen of our dedicated representative, has transformed our supply chain dynamics. Smart Stretch is not just a supplier; they are an integral part of our success story."
          }
          hideMore={true}
        />
        <Testimonial
          person={"Abdul, Distribution centre"}
          quote={
            "Choosing Smart Stretch for our packaging and logistics needs was a strategic decision that continues to pay dividends. The synergy between our team and Smart Stretch is evident in every transaction. Their proactive problem-solving, paired with a keen understanding of our evolving requirements, positions them as a valued partner in our growth journey."
          }
          hideMore={true}
        />
        <Testimonial
          person={"Robert, Displays manufacturer"}
          quote={
            "At Smart Stretch, we rely on a comprehensive range of consumable items provided by our perfect single source – Smart Stretch Supplies. Ensuring an ample supply, Smart Stretch is the epitome of supreme service. Our dedicated representative is not only excellent but genuinely cares about our business, going above and beyond to source new supplies and meet our growing demand. Constantly reviewing our current trends, the representative offers valuable suggestions for improvements and alternatives, enabling us to stay efficient and cost-conscious in today's economic landscape."
          }
          hideMore={true}
        />
        <Testimonial
          person={"James, Inventory control specialist"}
          quote={
            "We've been using Smart Stretch for our packaging needs, and their commitment to quality is unmatched. The stretch wrap is durable and reliable, ensuring our products reach customers in perfect condition every time."
          }
          hideMore={true}
        />
        <Testimonial
          person={"Paul, Distribution center manager "}
          quote={
            "Our experience with Smart Stretch has been exceptional. From placing orders to receiving shipments, their customer service is top-notch. They truly prioritize customer satisfaction, making them our go-to for stretch wrap solutions."
          }
          hideMore={true}
        />
        <Testimonial
          person={"Vida, Logistics planner"}
          quote={
            "We appreciate Smart Stretch's commitment to sustainability. Their stretch wrap not only meets our packaging needs but also aligns with our eco-friendly initiatives. A reliable partner for businesses looking to make environmentally conscious choices."
          }
          hideMore={true}
        />
        <Testimonial
          person={"Moses, Material handler"}
          quote={
            "Smart Stretch's hand-use wrap ensures consistent, reliable performance. A staple in our packaging process!"
          }
          hideMore={true}
        />
        <Testimonial
          person={"Gabriel, Transportation manager"}
          quote={
            "Smart Stretch's wrap not only saves costs but also delivers quality. A cost-efficient solution with minimal material wastage!"
          }
          hideMore={true}
        />
      </div>
    </>
  );
}

export default Testimonials;
