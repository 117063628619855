import { Link } from "react-router-dom";
import "./fourOhFour.scss";

function FourOhFour() {
  return (
    <>
      <div className="fourOhFour__container">
        <p className="fourOhFour__warning">Oh no, the page you're looking for doesn't exist</p>

        <Link to={"/"}>
          <p className="fourOhFour__homeLink">Return to home page</p>
        </Link>
        <img />
      </div>
    </>
  );
}

export default FourOhFour;
