import { useEffect, useState } from "react";
import quotation from "../../assets/icons/quotes.png";
import "./Testimonial.scss";
import { Link } from "react-router-dom";

function Testimonial({ person, quote, hideMore }) {
  let hide = {};

  if (hideMore === true) {
    hide = { display: "none" };
  }

  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <div
        className={`modal ${modalOpen ? "open" : "closed"}` }
        onClick={handleClick}
      >
        <div className="testimonial__quote-box">
          <img
            src={quotation}
            className="testimonial__quote-icon"
            alt="quotation icon"
          />
          <p className="testimonial__quote">{quote}</p>
        </div>
        <p className="testimonial__individual">{person}</p>
        <Link to={"testimonials"}>
          <p className="testimonial__viewMore" style={hide}>
            more testimonails
          </p>
        </Link>
      </div>
    </>
  );
}

export default Testimonial;
