import React from "react";

function MailTo() {
  const recipientEmail = "sales@smartstretch.ca";
  const subject = "StretchWrapInquiry";

  const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}`;

  return <a href={mailtoLink}>{recipientEmail}</a>;
}

export default MailTo;
