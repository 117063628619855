import "./WrapDesc.scss";

function WrapDesc({ header, paragraph, imgSrc }) {
  return (
    <>
      <div className="wrapDesc">
        <h3 className="wrapDesc__header">
          {header}
          <span className="wrapDesc__period">.</span>
        </h3>
        <p className="wrapDesc__paragraph">
          {paragraph}
          <span className="wrapDesc__period">.</span>
        </p>
        <img
          src={imgSrc}
          alt={`an application of ${header} wrap`}
          className="wrapDesc__img"
        />
      </div>
    </>
  );
}

export default WrapDesc;
