//packages
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useEffect } from "react";

//components
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import FourOhFour from "./pages/FourOhFour/FourOhFour";

//pages
import Home from "./pages/Home/Home";
import Testimonials from "./pages/Testimonials/Testimonials";
import Contact from "./pages/Contact/Contact";
import OurWrap from "./pages/OurWrap/OurWrap";

//Style sheets
import "./styles/partials/_global.scss";

function App() {
  useEffect(() => {
    document.title = "Smart Stretch";
  }, []);

  return (
    <BrowserRouter>
      <div className="app-container">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/our-wrap" element={<OurWrap />} />
          <Route path="*" element={<FourOhFour />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
