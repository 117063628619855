import { Link } from "react-router-dom";

import MailTo from "../MailTo/MailTo";
import Tel from "../Tel/Tel";

import "./footer.scss";

function Footer() {
  return (
    <>
      <footer className="footer__container">
        <div className="footer">
          <div className="footer__ctaBox">
            <p className="footer__ctaHaveQs">
              Have questions<span className="footer__ctaQmark">?</span>
            </p>
            <p className="footer__ctaGiveCall">Give us a call today</p>
            <p className="footer__ctaPhone"><Tel/></p>

            <Link to="/">
              <p className="footer__ctaLogo">SS</p>
            </Link>
          </div>

          <div className="footer__contact">
            <p>Contact Us</p>
            <p><Tel/></p>
              <MailTo/>
          </div>
        </div>
        <div className="footer__copyright">
          <p>© 2024 SmartStretch. All Rights Reserved.</p>
        </div>
      </footer>
    </>
  );
}

export default Footer;