import { Link } from "react-router-dom";
import "./Intro.scss";

function Intro() {
  return (
    <>
      <div className="intro" id="intro">
        <div className="intro__txt-box">
          <p className="intro__title">Our Stretch Wrap & Film</p>
          <p className="intro__paragraph">
            A versatile elastic plastic wrap made from stretchable Linear
            low-density polyethylene (LLDPE), ideal for securing and bundling
            various products such as fragile items, pallet shipments, and for
            transportation and storage. This film tightly binds and protects,
            preventing damage.
          </p>
        </div>
        <div className="intro__txt-box">
          <p className="intro__title">Types Of Stretch Film</p>
          <p className="intro__paragraph">
            Smart Stretch offers a diverse range of stretch films in various
            widths and thicknesses, available in standard sizes for both machine
            and hand wrap applications. Additionally, custom thicknesses and
            lengths can be tailored to meet specific customer needs.
          </p>
        </div>
      </div>
      <div className="intro__learn-more-btn-box">
        <Link to={"/our-wrap"} class="intro__learn-more-btn">
          <span class="intro__learn-more-btn-span">Learn More</span>
        </Link>
      </div>
    </>
  );
}

export default Intro;
