import React, { useState, useEffect } from "react";
import "./ourWrap.scss";

import handWrapImg from "../../assets/pictures/ssstills/IMG_0872-min.JPG";
import machineWrap from "../../assets/pictures/ssstills/IMG_0933-min.JPG";

import image2 from "../../assets/pictures/ssstills/IMG_1085-min.JPG";
import image3 from "../../assets/pictures/ssstills/IMG_1076-min.JPG";
import image4 from "../../assets/pictures/ssstills/IMG_1098-min.JPG";
import image5 from "../../assets/pictures/ssstills/IMG_1108-min.JPG";
import image6 from "../../assets/pictures/ssstills/IMG_0907-min.JPG";
import image7 from "../../assets/pictures/ssstills/IMG_0912-min.JPG";

function OurWrap() {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const imageBox = document.querySelector(".ourWrapIntro__imageBox");
    const imageBoxImages = [image2, image3, image4, image5, image6, image7];

    imageBoxImages.forEach((imgSrc) => {
      const img = new Image();
      img.src = imgSrc;
    });

    function changeImageBox() {
      imageBox.style.backgroundImage = `url(${imageBoxImages[index]})`;
      setIndex((prevIndex) => (prevIndex + 1) % imageBoxImages.length);
    }

    const intervalId = setInterval(changeImageBox, 4000);

    return () => clearInterval(intervalId);
  }, [index]);

  return (
    <>
      <div className="ourWrapPage">
        <div className="ourWrapIntro__container">
          <div className="ourWrapIntro">
            <h3 className="ourWrapIntro__header">What is Smart Stretch?</h3>
            <p className="ourWrapIntro__paragraph">
              <span className="ourWrapIntro__indent">Stretch </span> wrap is a
              versatile packaging material used to secure and protect palletized
              goods during transit or storage. SmartStretch's high-quality
              stretch wrap not only offers superior load stability but also
              ensures product integrity by safeguarding against dust, moisture,
              and external elements. With SmartStretch's commitment to providing
              cutting-edge packaging solutions, their stretch wrap products are
              designed to enhance efficiency and reliability in the supply
              chain.
            </p>
          </div>

          <div className="ourWrapIntro__imageBox">
            <p className="ourWrapIntro__imageBoxP"></p>
          </div>
        </div>

        <div className="ourWrap__container">
          <div className="ourWrapIntro__product">
            <h3 className="ourWrapIntro__header">Hand Wrap</h3>
            <div className="ourWrapIntro__productContent">
              <p className="ourWrapIntro__productParagraph">
                <div className="ourWrapIntro__imgBox">
                  <img src={handWrapImg} className="ourWrapIntro__imgTablet" />
                </div>
                Hand-used stretch wrap is a manual and versatile packaging
                solution that offers flexibility and control for securing loads.
                Ideal for small-scale operations or irregularly shaped pallets,
                hand stretch wrap allows workers to adjust tension and coverage
                based on the specific requirements of each package. Its
                user-friendly design enables efficient application without the
                need for specialized equipment. This method is particularly
                beneficial for businesses with varying load sizes and limited
                resources, as it provides a cost-effective and adaptable
                solution. Hand-used stretch wrap ensures that products remain
                tightly bound during transit, offering protection against
                shifting, dust, and moisture, ultimately preserving the
                integrity of the goods.
              </p>
              <div className="ourWrapIntro__imgBox">
                <img src={handWrapImg} className="ourWrapIntro__imgMobile" />
              </div>
            </div>
          </div>
          <div className="ourWrapIntro__product left">
            <h3 className="ourWrapIntro__header">Machine Wrap</h3>
            <div className="ourWrapIntro__productContent">
              <p className="ourWrapIntro__productParagraph">
                <div className="ourWrapIntro__imgBox">
                  <img src={machineWrap} className="ourWrapIntro__imgTablet" />
                </div>
                A high-speed, automated packaging solution suitable for
                large-scale operations. This technology significantly enhances
                efficiency and consistency in the wrapping process, making it
                ideal for businesses with high-volume production and
                distribution needs. Machine stretch wrap systems utilize
                advanced features such as pre-stretch capabilities, ensuring
                optimal film usage and load stability. The automated nature of
                these machines not only increases packaging speed but also
                minimizes labor costs. With precise tension control and the
                ability to handle diverse pallet sizes, machine-used stretch
                wrap offers a streamlined and reliable packaging solution for
                companies looking to optimize their supply chain operations.
              </p>
              <div className="ourWrapIntro__imgBox">
                <img src={machineWrap} className="ourWrapIntro__imgMobile" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurWrap;
