import ContactForm from "../../components/ContactForm/ContactForm";
import "./contact.scss";
import "../../styles/partials/_global.scss";

import MailTo from "../../components/MailTo/MailTo";
import Tel from "../../components/Tel/Tel";

function Contact() {
  return (
    <>
      <div className="contactPage">
        <div className="contactPage__hero">
          <p className="contactPage__heroHeader">Get in contact</p>
          <p className="contactPage__heroSubtitle">
            Secure deliveries, stress-free.
          </p>
        </div>
        <div className="contactPage__container">
          <div className="contactPage__form">
            <ContactForm />
          </div>

          <div className="contactPage__details">
            <p>Smart Stretch</p>
            <p>GD-003-0603, Adenta Municipality, Accra, Ghana</p>
            <p><Tel/></p>

            <p>
              <MailTo />
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
