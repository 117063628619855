import Hero from "../../components/Hero/Hero";
import Intro from "../../components/Intro/Intro";
import Testimonial from "../../components/Quotes/Testimonial";
import WrapDesc from "../../components/WrapDesc/WrapDesc";
import WrapBulletPoint from "../../components/WrapBulletPoint/WrapBulletPoint";
import ContactForm from "../../components/ContactForm/ContactForm.js";

import "./Home.scss";

import { Link } from "react-router-dom";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

import machineWrap from "../../assets/pictures/ssstills/IMG_1117-min.JPG";
import handWrapImg from "../../assets/pictures/ssstills/IMG_1133-min.JPG";

//Icon Import
import moneyBullet from "../../assets/icons/iconBlack/tabler_coin.svg";
import sustainabiityBullet from "../../assets/icons/iconBlack/carbon_sustainability.svg";
import customBullet from "../../assets/icons/iconBlack/clarity_slider-solid.svg";
import bestSellerBullet from "../../assets/icons/iconBlack/mdi_graph-line.svg";
import inventoryBullet from "../../assets/icons/iconBlack/solar_box-broken.svg";
import performanceBullet from "../../assets/icons/iconBlack/circum_dumbbell.svg";

function Home() {
  return (
    <>
      <Hero />
      <Intro />
      <div className="homePage__testimonial__pair">
        <div className="homePage__testimonial__first">
          <Testimonial
            person={"- Sameul, Print company"}
            quote={
              "We're delighted with our collaboration with Smart Stretch, particularly our outstanding Sales Representative. They truly understand our business and shipping needs, consistently delivering top-notch service and quality products at competitive prices."
            }
          />
        </div>

        <div className="homePage__testimonial__second">
          <Testimonial
            person={"- Charles, Apparel distribution center"}
            quote={
              "Smart Stretch consistently offers an extensive range of products for our diverse needs. The sales team is not only friendly but also highly informative, ensuring a seamless and insightful experience. We can always rely on Smart Stretch for timely deliveries, making our ordering process a breeze."
            }
          />
        </div>

        <div className="homePage__testimonial__other">
          <Testimonial
            person={"- Kwame, Fulfillment industry"}
            quote={
              "The smartest move we made in securing warehouse supplies was discovering Smart Stretch. With an exceptionally knowledgeable representative, outstanding service, and, of course, highly competitive pricing, Smart Stretch has proven to be our best choice. We couldn't be more delighted with the partnership."
            }
          />
        </div>

        <div className="homePage__testimonial__other">
          <Testimonial
            person={"- Mark, Hospitality industry"}
            quote={
              "Smart Stretch stands out with exceptional service and expertise in addressing our packaging and warehouse requirements. Their support ensures efficient and timely delivery of our products to customers, contributing significantly to our overall operational success."
            }
          />
        </div>
      </div>

      <div className="homePage__wrapIntro">
        <div className="homePage__wrapIntro__section">
          <h2 className="homePage__wrapIntro__title">Our Product Offerings</h2>
          <p className="homePage__wrapIntro__paragraph">
            As industry experts in stretch wrap and film packaging solutions, we
            have curated a comprehensive offering that addresses all your
            shipping requirements. From high-quality stretch wrap materials to
            cutting-edge film packaging technologies, we ensure a complete and
            tailored solution for your specific packaging needs.
          </p>
        </div>

        <div className="homePage__wrapIntroCards">
            <WrapDesc
              header={"Hand wrap"}
              paragraph={
                "Hand stretch film is made exclusively to be wrapped by human labor manually. It is also known as hand film, hand stretch wrap, hand wrap, or manual pallet wrap. It is commonly used by companies that wrap individual products and very light loads. Hand wrap will protect the product to ensure it arrives at its destination undamaged"
              }
              imgSrc={handWrapImg}
            />
          <WrapDesc
            header={"Machine Wrap"}
            paragraph={
              "Machine stretch film was made to be used by a stretch wrap machine. Machine stretch film has a quicker and greater efficiency in packaging, lower material spending, greater safety and superior load containment"
            }
            imgSrc={machineWrap}
          />
        </div>
      </div>

      <div className="homePage__ourWrap">
        <div className="homePage__ourWrap__intro">
          <h2 className="homePage__ourWrap__header">Our High Performance Wrap</h2>
          <p className="homePage__ourWrap__p">
            Recent advancements in cast film production have given rise to
            high-performance stretch film. This innovative, engineered film,
            crafted through a sophisticated multilayered resin process, offers
            superior clarity, cling, and resistance to punctures and tears
            compared to traditional film.
          </p>
        </div>
        <div className="homePage__ourWrap__list">
          <WrapBulletPoint
            imgSoure={performanceBullet}
            indent={"Optimal performance"}
            copy={
              "High-performance stretch films excel in load containment, offering superior puncture resistance, cling, and tear resistance compared to traditional films. Due to these benefits, a growing number of customers are adopting high-performance films, minimizing the need for rewrapping."
            }
          />
          <WrapBulletPoint
            imgSoure={sustainabiityBullet}
            indent={"Sustainability"}
            copy={
              "High-performance film offers a more sustainable packaging solution by significantly reducing packaging waste. With the ability to stretch up to 300% on stretch wrappers, it utilizes less film per load, leading to savings of 25% or more in film usage without compromising load containment. This reduction in packaging waste is achieved through: a. Using less plastic for load wrapping. b. Securely containing products, minimizing damaged merchandise discarded in landfills."
            }
          />
          <WrapBulletPoint
            imgSoure={moneyBullet}
            indent={"Cost-Savings"}
            copy={
              "High-performance films deliver clear cost savings by requiring less plastic per load for effective wrapping and securing. With enhanced load containment, there's reduced product damage, leading to additional savings by avoiding the need for rewrapping."
            }
          />
          <WrapBulletPoint
            imgSoure={bestSellerBullet}
            indent={"Bestseller"}
            copy={
              "Our top-selling wraps are 45cm x 33cm micro rolls with an impressive 1509% stretch factor. These rolls stretch effectively, adhering to the product or load for secure wrapping and preventing any potential damage."
            }
          />
          <WrapBulletPoint
            imgSoure={inventoryBullet}
            indent={"Inventory"}
            copy={
              "SmartStretch offers top-of-the-line quality at market-competitive prices. Reach out for a personalized assessment of the ideal products for your needs. Benefit from our strategically located inventory in Accra, Ghana, ensuring a seamless and uninterrupted supply."
            }
          />
          <WrapBulletPoint
            imgSoure={customBullet}
            indent={"Customization Options"}
            copy={
              "At Smart Stretch, we specialize in customizable stretch wrap solutions tailored to your unique shipping needs. From varying thickness options to formulations for specific conditions, our products ensure secure and efficient packaging. Choose Smart Stretch for personalized solutions that adapt seamlessly to your shipping challenges."
            }
          />
        </div>
      </div>
      <div className="homePage__contact-section">
        <ContactForm />
      </div>
    </>
  );
}

export default Home;
